import { ApolloProvider } from '@apollo/client';
import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { createApolloClient } from './apollo';
import { useSessionState } from './hooks';
import Settings from './settings';

export const AuthContext = createContext<any>(null);

export interface AuthInfo {
    state: "authenticated" | "unauthenticated"
    user?: string
    jwt?: string
}

export function useAuth() {
    return useSessionState<AuthInfo|null>('auth')
}

type RestrictedProps = React.PropsWithChildren<{}>;
type AuthProps = React.PropsWithChildren<{}>;

export function AuthProvider(props: AuthProps) {
    const [auth, setAuth] = useAuth()
    const context = {auth, setAuth}
    return (
      <AuthContext.Provider value={context}>
          {props.children}
      </AuthContext.Provider>    
    );  
}

export function Restricted(props: RestrictedProps) {
    const {auth} = useContext(AuthContext)
    const history = useHistory()
    const { t } = useTranslation()
    if (!auth) {
        history.replace('/')
        return <span className="text-danger">
            {t('Authentication required')}
        </span>
    }
    else {
        const client = createApolloClient(auth.jwt);
        return (
            <ApolloProvider client={client}>
                {props.children}
            </ApolloProvider>
        )
    }
}

export async function authTenant(email: string, password: string): Promise<AuthInfo> {
    const url = `${Settings.apiUrl}/tenant/auth`
    const response = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "post",
        mode: "cors",
        body: JSON.stringify({email, password})
    })
    if (response.status === 200) {
        const json = await response.json()
        return json as AuthInfo
    }
    else {
        return {
            state: "unauthenticated"
        }
    }
}
