const ProdSettings = {
    authUrl: "wss://api.certihash.com/web/auth",
    apiUrl: "https://api.certihash.com/api",
    graphqlUrl: "https://graphql.certihash.com/v1/graphql",
};

const DevSettings = {
    authUrl: "ws://api-dev.certihash.com:9080/web/auth",
    apiUrl: "http://api-dev.certihash.com:9080/api",
    graphqlUrl: "http://graphql-dev.certihash.com:8099/v1/graphql",
};

export default process.env.NODE_ENV === 'development' ? DevSettings : ProdSettings;
