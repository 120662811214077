import { Card } from 'primereact/card';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Button} from 'primereact/button';
import { useTranslation } from 'react-i18next';
import './styles/login.scss';
import { useContext, useState } from 'react';
import { AuthContext, authTenant } from './services/auth';
import { useHistory } from 'react-router';

export default function LoginPage() {
    const {t} = useTranslation()
    const {setAuth} = useContext(AuthContext)
    const [user, setUser] = useState('')
    const [pass, setPass] = useState('')
    const hist = useHistory()
    const login = async () => {
        const resp = await authTenant(user, pass)
        if (resp.state === "authenticated") {
            console.log("Ok")
            setAuth(resp)
            hist.push("/dashboard")
        }
        else {
            console.log("Denied")
        }
    }
    return (
        <div className="login-page">
            <Card title={t('Login')} style={{width: "500px"}}>
                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="p4z1gHuuZR">{t('Username')}:</label>
                        <InputText id="p4z1gHuuZR" type="text" value={user} onChange={(e : any) => setUser(e.target.value)} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="zyWlqeR6CU">{t('Password')}:</label>
                        <Password id="zyWlqeR6CU" feedback={false} value={pass} onChange={(e : any) => setPass(e.target.value)} />
                    </div>
                </div>    
                <Button label={t('Login')} onClick={login} />            
            </Card>
        </div>
    )
}