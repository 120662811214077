import React from 'react';
import './styles/App.css';
import AppRouter from './routes';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { AuthProvider } from './services/auth';

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppRouter />
      </Router>
    </AuthProvider>
  );
}

export default App;
