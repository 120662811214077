import { useMutation, useQuery } from "@apollo/client";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { AuthContext } from "./services/auth";
import { QUERY_CONFIG, UPDATE_CONF_NEWCREDIT } from "./services/gql/config";
import { QUERY_FARES, UPDATE_FARE_2 } from "./services/gql/fares";
import { QUERY_STATS } from "./services/gql/stats";

export interface DashboardProps {

}

function StatsTable() {
    const {t} = useTranslation()
    const [certs, setCerts] = useState(0.0)
    const [tokens, setTokens] = useState(0.0)
    const [validation, setValidation] = useState(0.0)
    const {data} = useQuery(QUERY_STATS)
    useEffect(() => {
        if (data) {
            setCerts(data.ch_certificate_aggregate.aggregate.count)
            setTokens(data.ch_token_aggregate.aggregate.count)
            setValidation(data.ch_hash_aggregate.aggregate.sum.validation_count_)
        }
    }, [data])
    return (
        <>
        <table className="x-table">
            <thead>
                <tr>
                    <th>{t('Item')}</th>
                    <th>{t('Quantity')}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{t('Certificates issued')}:</td>
                    <td style={{textAlign:"right"}}>{certs}</td>
                </tr>
                <tr>
                    <td>{t('Tokens issued')}:</td>
                    <td style={{textAlign:"right"}}>{tokens}</td>
                </tr>
                <tr>
                    <td>{t('Validation requests')}:</td>
                    <td style={{textAlign:"right"}}>{validation}</td>
                </tr>
            </tbody>
        </table>
        </>
    )
}


export default function Dashboard(props: DashboardProps) {
    const {t} = useTranslation()
    const [fare, setFare] = useState(0.0)
    const [freec, setFreec] = useState(0.0)
    const {data} = useQuery(QUERY_FARES)
    const {data: freec_data} = useQuery(QUERY_CONFIG)
    const [saveFare, { data: fareResult }] = useMutation(UPDATE_FARE_2);
    const [saveFreec, { data: freecResult }] = useMutation(UPDATE_CONF_NEWCREDIT);
    const auth = useContext(AuthContext)
    const history = useHistory()

    useEffect(() => {
        if (data) {
            setFare(data.ch_token_fare_by_pk.fare_)
        }
        if (freec_data) {
            setFreec(freec_data.ch_tenant_config_by_pk.new_account_balance_)
        }
        console.log(data)
        console.log(freec_data)
    }, [data, freec_data])
    return (
        <>
            <div className="header-section">
                <h1 className="dashboard-name">Certihash.com</h1>
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    auth.setAuth(null);
                    history.push("/");
                }}>{t('Logout')}</a>
            </div>

            <div className="dashboard-wrapper" style={{padding: "4rem"}}>
                <div className="p-d-flex p-jc-center">
                    <Card title={t('Fares')} className="x-margins">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="fare-1">{t('Certification Token')}:</label>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        USD$
                                    </span>
                                    <InputText id="fare-1" type="text" value={fare} onChange={(e:any) => setFare(e.target.value)} />
                                </div>                                
                            </div>               
                        </div>        
                        <Button onClick={() => {
                            const v = parseFloat(fare as any) || 0
                            saveFare({variables: { fare: v }})
                            setFare(v)
                        }}>{t('Save')}</Button> 
                    </Card>
                    <Card title={t('On Boarding')} className="x-margins">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="freec">{t('Free credit for new users')}:</label>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        USD$
                                    </span>
                                    <InputText id="freec" type="text" value={freec} onChange={(e:any) => setFreec(e.target.value) } />
                                </div>                                
                            </div>               
                        </div>         
                        <Button onClick={() => {
                            const v = parseFloat(freec as any) || 0
                            saveFreec({variables: { fare: v }})
                            setFreec(v)
                        }}>{t('Save')}</Button> 
                    </Card>
                    <Card title={t('Transactions')} className="x-margins">
                        <StatsTable />
                    </Card>
                </div>
            </div>
        </>
    )
}