import { gql } from "@apollo/client";

export const QUERY_CONFIG = gql`
query QUERY_CONFIG {
    ch_tenant_config_by_pk(id_: "1") {
        new_account_balance_
    }
}
`;

export const UPDATE_CONF_NEWCREDIT = gql`
mutation UPDATE_CONF_NEWCREDIT($fare : numeric!) {
    update_ch_tenant_config(where: {id_: {_eq: 1}}, _set: {new_account_balance_: $fare}) {
      affected_rows
    }
}
`;
