import React from "react";
import {
    Switch,
    Route
} from "react-router-dom";
import Dashboard from "./dashboard";
import LoginPage from "./login";
import { Restricted } from "./services/auth";

export default function AppRouter() {
    return (
        <Switch>
            <Route exact path="/">
                <LoginPage />
            </Route>
            <Route exact path="/dashboard">
                <Restricted>
                    <Dashboard />
                </Restricted>
            </Route>
        </Switch>
    )
}
