import React from "react";

function serialize(value: any) {
    if (value instanceof Map) {
        return JSON.stringify({
            dataType: 'Map',
            value: Array.from(value.entries()),
        });
    } else {
        return JSON.stringify(value);
    }
}

function unserialize(json: string) {
    const value = JSON.parse(json)
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}

/**
 * This utility converts an async function into a normal function
 * that calls the original.
 * @param afn async function
 * @returns nothing
 */
export function asyncEffect(afn: any) {
    const fn = () => {
        afn();
    }
    return fn;
}

/**
 * This hook keeps state in sessionStorage
 * @param key name of the value
 * @returns 
 */
export function useSessionState<T>(key: string, defaultValue?: T) {
    const load = () => {
        let initial: any = sessionStorage.getItem(key);
        if (initial) {
            try {
                return unserialize(initial) as T
            }
            catch (error) {
                return defaultValue
            }
        }
        else {
            return defaultValue
        }
    }
    const [value, setValue] = React.useState(load);
    React.useEffect(() => {
        sessionStorage.setItem(key, serialize(value));
    }, [value, key]);
    return [value, setValue] as const;
}

/**
 * This hook keeps state in localStorage
 * @param key name of the value
 * @returns 
 */
export function useLocalState<T>(key: string, defaultValue: T) {
    const load = () => {
        let initial: any = localStorage.getItem(key);
        if (initial) {
            try {
                return unserialize(initial) as T
            }
            catch (error) {
                return defaultValue
            }
        }
        else {
            return defaultValue
        }
    }
    const [value, setValue] = React.useState<T>(load);
    React.useEffect(() => {
        localStorage.setItem(key, serialize(value));
    }, [value, key]);
    return [value, setValue] as const;
}

