import { gql } from "@apollo/client";

export const QUERY_STATS = gql`
query QUERY_STATS {
    ch_certificate_aggregate(where: {certificate_hash_: {_is_null: false}}) {
        aggregate {
        count(columns: certificate_hash_)
        }
    }
    ch_token_aggregate {
        aggregate {
        count(columns: id_)
        }
    }
    ch_hash_aggregate {
        aggregate {
          sum {
            validation_count_
          }
        }
    }
}
`;