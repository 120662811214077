import { gql } from "@apollo/client";

export const QUERY_FARES = gql`
query QUERY_FARES {
    ch_token_fare_by_pk(id_: 2) {
        fare_
    }
}
`;

export const UPDATE_FARE_2 = gql`
mutation UPDATE_FARE($fare : numeric!) {
    update_ch_token_fare(where: {id_: {_eq: 2}}, _set: {fare_: $fare}) {
      affected_rows
    }
}
`;
